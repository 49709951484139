import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customerService from '../../services/customer.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const listProducts = createAsyncThunk('customer/list_products', async (_, { rejectWithValue }) => {
  try {
    return await customerService.listProducts();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listProductsByLocation = createAsyncThunk('customer/list_products_by_location', async (coordinate, { rejectWithValue }) => {
  try {
    return await customerService.listProductsByLocation(coordinate);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listVendorProducts = createAsyncThunk('customer/list_vendor_products', async (id, { rejectWithValue }) => {
  try {
    return await customerService.listVendorProducts(id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorProfile = createAsyncThunk('customer/get_vendor_profile', async (data, { rejectWithValue }) => {
  try {
    return await customerService.getVendorProfile(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorScheduleClose = createAsyncThunk('customer/get_vendor_profile', async (data, { rejectWithValue }) => {
  try {
    return await customerService.getVendorScheduleClose(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorCapacity = createAsyncThunk('customer/get_vendor_capacity', async (data, { rejectWithValue }) => {
  try {
    return await customerService.getVendorCapacity(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listOrders = createAsyncThunk('customer/list_orders', async (_, { rejectWithValue }) => {
  try {
    return await customerService.listOrders();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const requestLalamoveQuotation = createAsyncThunk('customer/lalamove_quotation', async (data, { rejectWithValue }) => {
  try {
    return await customerService.requestLalamoveQuotation(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getOrder = createAsyncThunk('customer/get_order', async (data, { rejectWithValue }) => {
  try {
    return await customerService.getOrder(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const makePayment = createAsyncThunk('customer/make_payment', async (data, { rejectWithValue }) => {
  try {
    return await customerService.makePayment(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const cancelPayment = createAsyncThunk('customer/cancel_payment', async (data, { rejectWithValue }) => {
  try {
    return await customerService.cancelPayment(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const submitReview = createAsyncThunk('customer/submit_review', async ({ id, data }, { rejectWithValue }) => {
  try {
    return await customerService.submitReview(id, data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const retrieveImpersonateSession = createAsyncThunk('admin/service/retrieve-impersonate-session`', async (id, { rejectWithValue }) => {
  try {
    return await customerService.retrieveImpersonateSession(id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getQuote = createAsyncThunk('quote', async (data, { rejectWithValue }) => {
  try {
    return await customerService.getQuote(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const acceptQuote = createAsyncThunk('quote', async (data, { rejectWithValue }) => {
  try {
    return await customerService.acceptQuote(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: {
    /** List products reducer **/
    [listProducts.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listProducts.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listProducts.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** List products by location reducer **/
    [listProductsByLocation.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listProductsByLocation.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listProductsByLocation.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** List vendor products reducer **/
    [listVendorProducts.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listVendorProducts.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listVendorProducts.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get vendor profile reducer **/
    [getVendorProfile.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getVendorProfile.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getVendorProfile.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get vendor capacity reducer **/
    [getVendorCapacity.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getVendorCapacity.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getVendorCapacity.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** List orders reducer **/
    [listOrders.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listOrders.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listOrders.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },

    /** List orders reducer **/
    [requestLalamoveQuotation.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [requestLalamoveQuotation.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [requestLalamoveQuotation.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },

    /** Get order reducer **/
    [getOrder.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getOrder.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getOrder.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Make payemnt reducer **/
    [makePayment.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [makePayment.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [makePayment.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** cancel payemnt reducer **/
    [cancelPayment.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [cancelPayment.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [cancelPayment.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** submit review reducer **/
    [submitReview.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [submitReview.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [submitReview.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get quote reducer **/
    [getQuote.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getQuote.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getQuote.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
