import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../store/slices/components.slice';
import { makePayment, getQuote, acceptQuote } from '../../store/slices/customer.slice';
import { classNames } from '../../utils';
import { ModalQuote } from '../../components/Modal';
import { Form, Input } from '../../components/Form';
import LoadingScreen from '../../components/LoadingScreen';

const QuoteDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [quote, setQuote] = useState({});
  const [custInfo, setCustInfo] = useState(null);
  const { customerApi } = useSelector((state) => state);
  const formNode = useRef();
  const [formErrors, setFormErrors] = useState([]);
  const [customerName, setCustomerName] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [notAvailable, setNotAvailable] = useState(false);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    if (userInfo) {
      setCustInfo(userInfo);
    }
  }, [localStorage.getItem('user')]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    if (userInfo) {
      setCustInfo(userInfo);
    }
    dispatch(getQuote({ id: id }))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setQuote(data?.quote);
        const guest = JSON.parse(localStorage.getItem('guest'));
        if (!data?.quote || data.quote?.isExpired) {
          setNotAvailable(true);
        } else if (data.quote?.orderUid) {
          if (guest?.email || userInfo) {
            history.push(`/orders/${data.quote?.orderUid}`);
          } else {
            setNotAvailable(true);
          }
        }
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  }, []);

  const handlePayment = (id) => {
    dispatch(
      makePayment({ id: id, returnSuccessUrl: `${window.location.origin}/orders/${id}/payment/success`, returnCancelUrl: `${window.location.origin}/orders/${id}/payment/failed` })
    )
      .unwrap()
      .then(({ data }) => {
        const { paymentLink } = data;
        window.location.replace(paymentLink);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleAcceptQuote = () => {
    if (!custInfo) {
      const guest = { email: customerEmail, name: customerName };
      localStorage.setItem('guest', JSON.stringify(guest));
    }

    const data = {
      quoteUid: id,
      name: custInfo?.name || customerName,
      email: custInfo?.email || customerEmail,
    };

    dispatch(acceptQuote(data))
      .unwrap()
      .then((result) => {
        dispatch(showToast({ show: true, type: 'success', message: `Your quote has been successfully accepted and proceed to payment` }));
        if (result?.data?.order?.status === 'COMPLETED') {
          history.push(`/orders/${result?.data?.order?.uid}`);
        } else {
          handlePayment(result?.data?.order?.uid);
        }
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return (
    <>
      <LoadingScreen show={customerApi.loading} />
      {!notAvailable && (
        <div className="space-y-6 max-w-3xl mx-auto py-6 mb-20 px-4 sm:px-6 lg:px-8 min-h-screen">
          <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md ">
            <div className="grid grid-cols-4 py-6 px-4 sm:px-6 gap-4 sm:gap-4 border-t">
              <div className="col-span-4 sm:col-span-2">
                <span className="font-medium text-sm">Quote ID</span>
                <p className="text-sm text-gray-500">{id}</p>
              </div>
              <div className="col-span-4 sm:col-span-2">
                <span className="font-medium text-sm">Customer Phone Number</span>
                <p className="text-sm text-gray-500">{quote?.customerContactNumber}</p>
              </div>
            </div>

            <div className="border-t flex-1 py-6 overflow-y-auto px-4 sm:px-6">
              <div>
                <div className="flow-root">
                  <ul role="list" className="-my-6 divide-y divide-gray-200">
                    {quote?.products &&
                      quote?.products.map((product, index) => (
                        <li key={index} className="py-6 flex">
                          <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                            <img src={product.info.picture.path.s} alt={product.info.name} className="w-full h-full object-center object-cover" />
                          </div>

                          <div className="ml-4 flex-1 flex flex-col">
                            <div>
                              <div className="flex justify-between text-base font-medium text-gray-900">
                                <h3>{product.info.name}</h3>
                                <p className="ml-4">RM {Number(product.price * product.quantity).toFixed(2)}</p>
                              </div>
                              <p className="mt-1 text-sm text-gray-500">
                                Qty {product.quantity} x RM {product.price.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    {quote?.discountPercentage != 0 && (
                      <li className="py-6 flex">
                        <div className="ml-4 flex-1 flex flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>{quote?.discountDescription}</h3>
                              <p className="ml-4">RM {quote?.discountQuantity?.toFixed(2)}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
              <div>
                <div className="text-sm text-gray-900">
                  <p className="font-medium">Quote Remark:</p>
                  <p className="mt-1 text-gray-500">{quote?.orderRemark || 'Not provided'}</p>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
              <div>
                <div className="flex justify-between text-xl font-medium text-gray-900">
                  <p>Total</p>
                  <p className="font-bold">RM {quote?.totalPrice?.toFixed(2)}</p>
                </div>
              </div>
            </div>
            <div className={classNames(!quote?.paymentStatus || quote?.paymentStatus === 'open' ? 'block' : 'hidden', 'pb-4 sm:pb-6 px-4 sm:px-6')}>
              <Form onSubmit={handleAcceptQuote} setRef={formNode} setErrors={formErrors}>
                <div className="mb-5">
                  <Input.Text
                    name="Name"
                    label="Your name"
                    placeholder="Your name"
                    disabled={custInfo?.name ? true : customerApi.loading}
                    rules={[{ required: true, message: 'This field is required' }]}
                    value={custInfo?.name}
                    retrieveValue={(e) => setCustomerName(e.target.value)}
                  />
                </div>
                <div className="mb-5">
                  <Input.Text
                    name="Email"
                    label="Your email address"
                    placeholder="Your email address"
                    disabled={custInfo?.email ? true : customerApi.loading}
                    rules={[
                      { required: true, message: 'This field is required' },
                      { pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, message: 'Wrong email format' },
                    ]}
                    value={custInfo?.email}
                    retrieveValue={(e) => setCustomerEmail(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  disabled={customerApi.loading}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Accept Quote
                </button>
              </Form>
            </div>
          </div>
        </div>
      )}
      <ModalQuote
        show={notAvailable}
        title="Quote Link Not Found"
        description="Either this quote already accepted or session has expired..."
        loading={false}
        closeable={false}
        confirmText="Close"
        confirmAction={() => {
          setNotAvailable(false);
          history.push('/');
        }}
      />
    </>
  );
};

export default QuoteDetails;
