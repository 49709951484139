import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input } from '../../../components/Form';
import { ModalQuote } from '../../../components/Modal';

import { showToast } from '../../../store/slices/components.slice';
import { listProducts, createQuote } from '../../../store/slices/vendor.slice';
import { ServiceChargePercentage } from '../../../utils';
import QRCode from 'react-qr-code';
import LoadingScreen from '../../../components/LoadingScreen';

const StoreQuoteCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formNode = useRef();

  const { vendorApi, fileApi } = useSelector((state) => state);
  const [formErrors, setFormErrors] = useState([]);
  const [newPrice, setNewPrice] = useState('');
  const [currentServiceChargePercentage, setCurrentServiceChargePercentage] = useState(ServiceChargePercentage);

  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentPrice, setCurrentPrice] = useState();
  const [currentIndex, setCurrentIndex] = useState();

  const [productOrder, setProductOrder] = useState([]);
  const [allProductOrder, setAllProductOrder] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [customerNumber, setCustomerNumber] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [quoteLink, setQuoteLink] = useState(null);
  const [openQR, setOpenQR] = useState(false);
  const [quoteUid, setQuoteUid] = useState();
  const [quoteRemark, setQuoteRemark] = useState('');

  useEffect(() => {
    handleListVendors();
  }, []);

  const handleListVendors = () => {
    dispatch(listProducts())
      .unwrap()
      .then((result) => {
        const { data } = result;
        const tempArray = data.products.map((object) => ({ ...object }));
        setTableData(tempArray);

        const tempArrayP = data.products.map(({ uid, price, name }) => ({ productUid: uid, price, name }));
        setProductOrder(tempArrayP);

        if (data?.serviceChargePercentage || data?.serviceChargePercentage == 0) {
          setCurrentServiceChargePercentage(data?.serviceChargePercentage);
        }
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const handleOnClickModal = (isOpen, currentPrice, index) => {
    setOpenModal(isOpen);
    setCurrentPrice(currentPrice);
    setCurrentIndex(index);
    setNewPrice('');
  };

  function handleNewPrice(e) {
    const price = e.target.value;
    setNewPrice(price);
  }

  function handleDiscount(e) {
    setDiscount(parseFloat(e.target.value));
  }

  const handleConfirm = (index) => {
    setOpenModal(false);
    if (newPrice != '') {
      tableData[index].price = parseFloat(newPrice);
      productOrder[index].price = parseFloat(newPrice);
      setTotalPrice(allProductOrder.reduce((accumulator, current) => accumulator + current.price * current.quantity, 0));
    }
  };

  const handleSubmitNewQuote = () => {
    if (allProductOrder && allProductOrder.length < 1) setErrorMessage('Please select at least one product.');
    else if (totalPrice > 0 && totalPrice < 2) setErrorMessage('Total price must be between RM 0 or more than RM 2');
    else {
      setErrorMessage(null);
      const data = {
        customerContactNumber: customerNumber,
        discountPercentage: discount,
        products: allProductOrder,
        orderRemark: quoteRemark,
      };
      dispatch(createQuote(data))
        .unwrap()
        .then((result) => {
          dispatch(showToast({ show: true, type: 'success', message: `Your quote has been successfully created!` }));
          setQuoteLink(result?.data?.quote?.link);
          setOpenQR(true);
          setQuoteUid(result?.data?.quote?.uid);
        })
        .catch(({ message }) => {
          dispatch(showToast({ show: true, type: 'error', message: message }));
        });
    }
  };

  const handleQuantitySum = (quantity, uid) => {
    setErrorMessage(null);
    const productIndex = productOrder.indexOf(productOrder.find(({ productUid }) => productUid === uid));
    productOrder[productIndex]['quantity'] = quantity;
    setProductOrder(productOrder);

    let tempProductOrder = productOrder.filter(({ quantity }) => quantity > 0);
    setAllProductOrder(tempProductOrder);
    setTotalPrice(tempProductOrder.reduce((accumulator, current) => accumulator + current.price * current.quantity, 0));
  };

  return (
    <>
      <LoadingScreen show={vendorApi.loading} />
      <ul className="hidden lg:flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium">
          <Link to="/store/quote">Quote</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-900 text-sm font-medium">Create</li>
      </ul>

      <div>
        <div className="grid grid-cols-3 gap-4 sm:gap-10">
          <div className="col-span-4 sm:col-span-2">
            <div className="text-lg font-bold">Store Products</div>
            <div className="border-t border-gray-200 pt-4 ">
              <Form setRef={formNode}>
                {/* web view */}
                <ul role="list" className="-my-6 divide-y divide-gray-200 sm:block hidden">
                  {tableData.map((product, index) => (
                    <li key={index} className="py-6 flex flex-col">
                      <div className="flex">
                        <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                          {product?.merdeka_promo_frame ? (
                            <div className="w-full h-full object-center object-cover">
                              <img src={product?.picture?.path?.s} alt={product?.uid} className="h-full" />
                            </div>
                          ) : (
                            <img src={product?.picture?.path?.s} alt={product?.uid} className="w-full h-full object-center object-cover" />
                          )}
                        </div>

                        <div className="ml-4 flex-1 flex justify-between">
                          <div className="flex flex-col text-base font-medium text-gray-900">
                            <h3>
                              <p>{product?.name}</p>
                            </h3>
                            <div className="flex flex-row">
                              <div>
                                <p className="text-gray-600 py-2 pr-5 ">RM {product?.price?.toFixed(2)}</p>
                              </div>
                              <div>
                                <button
                                  className="bg-primary-600 hover:bg-primary-700 text-gray-100 text-sm font-medium py-2 px-4 rounded ml-2"
                                  onClick={() => handleOnClickModal(true, product?.price, index)}
                                >
                                  Change Price
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="px-3">
                            <Input.Quantity
                              name={`products[${index}].quantity`}
                              disabled={vendorApi.loading}
                              canAddMore={true}
                              retrieveValue={({ value }) => handleQuantitySum(value, product?.uid)}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

                <ul role="list" className="-my-6 divide-y divide-gray-200 sm:hidden block">
                  {tableData.map((product, index) => (
                    <li key={index} className="py-6 flex flex-col">
                      <div className="flex">
                        <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                          {product?.merdeka_promo_frame ? (
                            <div className="w-full h-full object-center object-cover">
                              <img src={product?.picture?.path?.s} alt={product?.uid} className="h-full" />
                            </div>
                          ) : (
                            <img src={product?.picture?.path?.s} alt={product?.uid} className="w-full h-full object-center object-cover" />
                          )}
                        </div>

                        <div className="ml-4 flex-1 flex  place-items-center flex-row-reverse">
                          <div>
                            <Input.Quantity
                              name={`products[${index}].quantity`}
                              disabled={vendorApi.loading}
                              canAddMore={true}
                              retrieveValue={({ value }) => handleQuantitySum(value, product?.uid)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col text-base font-medium text-gray-900 py-3">
                        <div className="flex justify-between">
                          <p>{product?.name}</p>
                          <p className="text-gray-600">RM {product?.price.toFixed(2)}</p>
                        </div>
                      </div>
                      <div>
                        <button
                          className="bg-primary-600 hover:bg-primary-700 text-gray-100 text-sm font-medium py-2 px-4 rounded float-right"
                          onClick={() => handleOnClickModal(true, product?.price, index)}
                        >
                          Change Price
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </Form>
            </div>
          </div>
          <div className="col-span-4 sm:col-span-1 ">
            <div className="text-lg font-bold">Quote Summary</div>
            <div className="border-t border-gray-200 pt-4 " />
            <Form onSubmit={handleSubmitNewQuote} setRef={formNode} setErrors={formErrors}>
              <div>Product</div>
              {allProductOrder.map((product, index) => (
                <div key={product.uid} className="grid grid-cols-4 gap-4">
                  <div className="col-span-2">{product.name}</div>
                  <div>RM {product.price.toFixed(2)}</div>
                  <div>{product.quantity}</div>
                </div>
              ))}
              <div className="border-double border-2 mt-4 mb-4" />
              <div>Total Price: RM {totalPrice.toFixed(2)}</div>
              <div className="flex flex-row items-center">
                <Input.Text
                  label="Discount (%) :"
                  name="discount"
                  placeholder="eg: 60"
                  retrieveValue={handleDiscount}
                  disabled={vendorApi.loading}
                  rules={[{ pattern: /^(100(\.0+)?|(\d{1,2})(\.\d+)?)$/, message: 'Discount must be between 0 to 100 only' }]}
                />
              </div>
              <div className="border-double border-2 mt-4 mb-4" />
              <div>Net Price: RM {(totalPrice - totalPrice * (discount / 100)).toFixed(2)}</div>
              <div className="border-double border-2 mt-4 mb-4" />
              <div className="mt-2">
                <Input.Textarea
                  name="quoteRemark"
                  label="Quote Remark"
                  placeholder="Enter quote remark for this product"
                  fieldRows={2}
                  disabled={vendorApi.loading}
                  retrieveValue={(e) => setQuoteRemark(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <Input.Phone
                  name="phoneNumber"
                  label="Customer Phone number"
                  placeholder="e.g 60123456789"
                  disabled={vendorApi.loading}
                  retrieveValue={(e) => setCustomerNumber(e.target.value)}
                  rules={[
                    { required: true, message: 'This field is required' },
                    { pattern: /^((\+6|6)?01)[0-9]{8,9}$/, message: 'Wrong phone format' },
                  ]}
                />
              </div>
              <div className="mt-8" />
              {<p className="mt-2 text-xs text-red-500 tracking-wide font-medium sm:font-normal">{errorMessage}</p>}
              <button type="submit" className="bg-primary-600 hover:bg-primary-700 text-gray-100 text-sm font-medium py-2 px-4 rounded mt-2" disabled={vendorApi.loading}>
                Confirm Quote
              </button>
            </Form>
          </div>
        </div>
      </div>
      <ModalQuote
        show={openModal}
        title="Change Product Price"
        description="Click confirm after change product price."
        loading={false}
        closeable={true}
        cancelText="Cancel"
        cancelAction={() => setOpenModal(false)}
        confirmText="Confirm"
        confirmAction={() => handleConfirm(currentIndex)}
        children={
          <div className="grid grid-cols-2 gap-4 py-2 px-2">
            <div>
              <Form>
                <div className="col-span-6 sm:col-span-3">
                  <Input.Number type="Float" name="price" label="Current product price" value={currentPrice} />
                  <p className="mt-2 text-xs text-gray-500">
                    Service Fee {currentServiceChargePercentage}%: RM {(currentPrice * (currentServiceChargePercentage / 100)).toFixed(2)}{' '}
                  </p>
                  <p className="mt-2 text-xs text-gray-500">Net Price: RM {(currentPrice - currentPrice * (currentServiceChargePercentage / 100)).toFixed(2)}</p>
                </div>
              </Form>
            </div>
            <div>
              <Form>
                <div className="col-span-6 sm:col-span-3">
                  <Input.Number type="Float" name="newData.price" label="New product price" placeholder="eg: 20" retrieveValue={handleNewPrice} disabled={false} />
                  <p className="mt-2 text-xs text-gray-500">
                    Service Fee {currentServiceChargePercentage}%: RM {(newPrice * (currentServiceChargePercentage / 100)).toFixed(2)}
                  </p>
                  <p className="mt-2 text-xs text-gray-500">Net Price: RM {(newPrice - newPrice * (currentServiceChargePercentage / 100)).toFixed(2)}</p>
                </div>
              </Form>
            </div>
          </div>
        }
      />
      <ModalQuote
        show={openQR}
        title="Quote Successfully Created"
        description="Scan QR below to accept quote."
        loading={false}
        closeable={false}
        confirmText="Close"
        confirmAction={() => {
          setOpenQR(false);
          history.push(`/store/quote`);
        }}
        children={<>{quoteLink && <QRCode title="QuoteLink" value={quoteLink} />}</>}
      />
    </>
  );
};

export default StoreQuoteCreate;
